import { Col, Row } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import Title from "antd/es/typography/Title";
import { useEffect, useState } from "react";
import IAccountsReport from "../../../interfaces/IAccountsReport";
import axios from "axios";
import { API_URL } from "../../../settings";

function AccountsReport() {
    const [accountsReport, setAccountsReport] = useState<IAccountsReport[]>([]);
    var [isLoading, setIsLoading] = useState(false);

    const getAcNatures = () => {
        setIsLoading(true);
        axios
          .get(`${API_URL}/ac/reports/accounts-summary`)
          .then((response) => {
            response.data.map((x: { [x: string]: any; id: any }) => {
              x["key"] = x.id;
            });
            console.log(response.data)
            setAccountsReport(response.data);
            setIsLoading(false);
          })
          .catch((err) => {
            // Handle error
            console.log("server error");
            setIsLoading(false);
          });
      };

      useEffect(() => {
        getAcNatures();
    
        return () => {};
      }, []);

    // table rendering settings
    const accountsReportColumns: ColumnsType<IAccountsReport> = [
        {
            title: "Account Code",
            dataIndex: "accountCode",
            key: "accountCode",
        },
        {
            title: "Account Name",
            dataIndex: "accountName",
            key: "accountName",
            sorter: (a: IAccountsReport, b: IAccountsReport) =>
                a.accountName.localeCompare(b.accountName),
        },
        {
            title: "Total Debit",
            dataIndex: "totalDebit",
            key: "totalDebit",
            render: (value: number) => value.toFixed(2), // Formatting the number
            sorter: (a: IAccountsReport, b: IAccountsReport) =>
                a.totalDebit - b.totalDebit,
        },
        {
            title: "Total Credit",
            dataIndex: "totalCredit",
            key: "totalCredit",
            render: (value: number) => value.toFixed(2), // Formatting the number
            sorter: (a: IAccountsReport, b: IAccountsReport) =>
                a.totalCredit - b.totalCredit,
        },
        {
            title: "Balance",
            dataIndex: "balance",
            key: "balance",
            render: (value: number) => value.toFixed(2), // Formatting the number
            sorter: (a: IAccountsReport, b: IAccountsReport) =>
                a.balance - b.balance,
        },
    ];

    return (
        <>
            <Row>
                <Col md={24}>
                    <div>
                        <Title level={3}>Accounts Report</Title>
                        <Table
                            loading={isLoading}
                            size="small"
                            dataSource={accountsReport}
                            columns={accountsReportColumns}
                            scroll={{ x: "max-content" }} // Enables horizontal scrolling on smaller screens
                        />
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default AccountsReport;
