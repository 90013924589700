import { Col, Row } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import Title from "antd/es/typography/Title";
import { useEffect, useState } from "react";
import IAcNature from "../../../interfaces/IAcNature";
import { AcNatureType } from "../../Enum/AcNatureType";
import axios from "axios";
import { API_URL } from "../../../settings";

function AcNature() {
    const [acNatures, setAcNatures] = useState<IAcNature[]>([]);
    var [tableLoadingSpin, setTableSpinLoading] = useState(false);

    const getAcNatures = () => {
        setTableSpinLoading(true);
        axios
          .get(`${API_URL}/ac/charts`)
          .then((response) => {
            response.data.map((x: { [x: string]: any; id: any }) => {
              x["key"] = x.id;
            });
            setAcNatures(response.data);
            setTableSpinLoading(false);
          })
          .catch((err) => {
            // Handle error
            console.log("server error");
            setTableSpinLoading(false);
          });
      };

      useEffect(() => {
        getAcNatures();
    
        return () => {};
      }, []);

    const getEnumValue = (type: string): string => {
        switch (type) {
            case "ASSET":
                return AcNatureType.ASSET;
            case "LIABILITY":
                return AcNatureType.LIABILITY;
            case "INCOME":
                return AcNatureType.INCOME;
            case "EXPENSE":
                return AcNatureType.EXPENSE;
            default:
                return type; // Fallback to the original type if not found in enum
        }
    };

    // table rendering settings
    const acNatureColumns: ColumnsType<IAcNature> = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: "Code",
            dataIndex: "code",
            key: "code",
            sorter: (a, b) => a.code - b.code,
        },
        {
            title: "Type",
            dataIndex: "type",
            key: "type",
            render: (_: any, record: IAcNature) => {
                return getEnumValue(record.type);
            },
            sorter: (a, b) => a.type.localeCompare(b.type),
        },
        // {
        //     title: "Description",
        //     dataIndex: "description",
        //     key: "description",
        // },
    ];

    return (
        <>
            <Row>
                <Col md={24}>
                    <div>
                        <Title level={3}>Account Natures</Title>
                        <Table
                            loading={tableLoadingSpin}
                            size="small"
                            dataSource={acNatures}
                            columns={acNatureColumns}
                            pagination={{
                                showTotal: (total) => `Total ${total} records`,
                            }}
                            scroll={{ x: "max-content" }} // Enables horizontal scrolling on smaller screens
                        />
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default AcNature;
