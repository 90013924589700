import { useEffect, useState } from "react";
import {
  Button,
  Table,
  Modal,
  Form,
  Input,
  InputNumber,
  DatePicker,
  Space,
  Select,
  message,
  Row,
  Col,
  Divider,
  Typography,
  Card,
  Popconfirm,
} from "antd";
import { PlusOutlined, DeleteTwoTone, EditTwoTone } from "@ant-design/icons";
import axios from "axios";
import dayjs from "dayjs";
import { API_URL } from "../../../settings";
import { ColumnsType } from "antd/es/table";
import IAcVoucherType from "../../../interfaces/IAcVoucherType";
import IAcChart from "../../../interfaces/IAcChart";
import IAcVoucher from "../../../interfaces/IAcVoucher";
import IAcVoucherDetail from "../../../interfaces/IAcVoucherDetail";
import { FormListFieldData, FormListOperation } from "antd/es/form/FormList";
import FormatCurrencyWithSymbol from "../../../Util/FormatCurrencyWithSymbol";

const { Title } = Typography;
const DATE_FORMAT = "YYYY-MM-DD"; // Format for date display and submission

function AcVouchers() {
  // State declarations
  const [acVouchers, setAcVouchers] = useState<IAcVoucher[]>([]); // Array of vouchers for the table display
  const [acCharts, setAcCharts] = useState<IAcChart[]>([]); // Array of account charts for selection
  const [voucherTypes, setVoucherTypes] = useState<IAcVoucherType[]>([]); // Array of voucher types for selection
  const [loading, setLoading] = useState(false); // Loading indicator for data fetching
  const [isModalVisible, setIsModalVisible] = useState(false); // Modal visibility state
  const [defaultVoucherTypeId, setDefaultVoucherTypeId] = useState<number | null>(null); // Default voucher type ID
  const [isEditMode, setIsEditMode] = useState(false); // Determines if form is in edit mode
  const [form] = Form.useForm(); // Ant Design Form instance
  const [currentVoucherId, setCurrentVoucherId] = useState<number | null>(null); // Tracks the current voucher ID for editing

  // Fetches vouchers, account charts, and voucher types on component mount
  useEffect(() => {
    fetchVouchers();
    fetchAcCharts();
    fetchVoucherTypes();
  }, []);

  // Fetches all vouchers and sets the state
  const fetchVouchers = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}/ac/vouchers`);
      setAcVouchers(response.data);
    } catch (error) {
      console.error("Error fetching vouchers:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetches all account charts and sets the state
  const fetchAcCharts = async () => {
    try {
      const response = await axios.get(`${API_URL}/ac/charts`);
      setAcCharts(response.data);
    } catch (error) {
      console.error("Error fetching account charts:", error);
    }
  };

  // Fetches voucher types, determines the default type, and sets the state
  const fetchVoucherTypes = async () => {
    try {
      const response = await axios.get(`${API_URL}/ac/voucher-types`);
      setVoucherTypes(response.data);
      const defaultType = response.data.find((type: IAcVoucherType) => type.is_default === 1);
      if (defaultType) {
        setDefaultVoucherTypeId(defaultType.id);
      }
    } catch (error) {
      console.error("Error fetching voucher types:", error);
    }
  };

  // Opens the modal for creating a new voucher
  const handleAdd = () => {
    setIsEditMode(false);
    setCurrentVoucherId(null);
    form.resetFields();
    form.setFieldsValue({
      voucherTypeId: defaultVoucherTypeId,
      voucherDate: dayjs(),
    });
    setIsModalVisible(true);
  };

  // Opens the modal for editing an existing voucher
  const handleEdit = async (voucher: IAcVoucher) => {
    try {
      setIsEditMode(true);
      const response = await axios.get(`${API_URL}/ac/vouchers/${voucher.id}`);
      const fetchedVoucher = response.data;

      // Ensure numeric values for `dr` and `cr` fields in the voucher details
      const detailsWithNumericValues = fetchedVoucher.details.map((detail: IAcVoucherDetail): IAcVoucherDetail => ({
        ...detail,
        dr: detail.dr ? Number(detail.dr) : null,
        cr: detail.cr ? Number(detail.cr) : null,
      }));

      const getUniqueMonths = () => {
        return [
            ...new Set(
                acVouchers.map((item) => dayjs(item.voucherDate).format("MMMM"))
            ),
        ];
    };

    const getUniqueYears = () => {
        return [
            ...new Set(
                acVouchers.map((item) => dayjs(item.voucherDate).year())
            ),
        ];
    };

      setCurrentVoucherId(fetchedVoucher.id);
      form.setFieldsValue({
        ...fetchedVoucher,
        voucherDate: dayjs(fetchedVoucher.voucherDate),
        details: detailsWithNumericValues,
      });

      setIsModalVisible(true);
    } catch (error) {
      console.error("Error fetching voucher details:", error);
      message.error("Failed to load voucher details.");
    }
  };

  // Closes the modal and resets the form fields
  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  // Saves a new or updated voucher, depending on the mode
  const handleSave = async () => {
    try {
      const values = await form.validateFields();

      // Calculate total Dr and Cr values
      const totalDr = values.details.reduce((sum: number, item: IAcVoucherDetail) => sum + (item.dr || 0), 0);
      const totalCr = values.details.reduce((sum: number, item: IAcVoucherDetail) => sum + (item.cr || 0), 0);

      // Validation to ensure Dr and Cr are balanced and greater than zero
      if (totalDr !== totalCr) {
        message.error("Total Dr and Cr must be equal.");
        return;
      }
      if (totalDr <= 0 || totalCr <= 0) {
        message.error("Total Dr and Cr must be greater than 0.");
        return;
      }

      // Prepare the data object to be submitted
      const data = {
        ...values,
        voucherDate: values.voucherDate.format(DATE_FORMAT),
        details: values.details,
      };

      // Determine if creating or updating based on mode
      if (isEditMode && currentVoucherId) {
        await axios.put(`${API_URL}/ac/vouchers/${currentVoucherId}`, data);
        message.success("Voucher updated successfully");
      } else {
        await axios.post(`${API_URL}/ac/vouchers`, data);
        message.success("Voucher created successfully");
      }

      // Refresh vouchers and close modal
      fetchVouchers();
      handleCancel();
    } catch (error) {
      console.error("Error saving voucher:", error);
    }
  };

  // Deletes a specific voucher by ID
  const handleDelete = async (id: number) => {
    try {
      await axios.delete(`${API_URL}/ac/vouchers/${id}`);
      message.success("Voucher deleted successfully");
      fetchVouchers();
    } catch (error) {
      console.error("Error deleting voucher:", error);
      message.error("Failed to delete voucher");
    }
  };

  // Table columns configuration for vouchers display
  const columns: ColumnsType<IAcVoucher> = [
    {
      title: "Voucher No",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Date",
      dataIndex: "voucherDate",
      key: "voucherDate",
      render: (text: string) => dayjs(text).format(DATE_FORMAT),
    },
    {
      title: "Type",
      dataIndex: "voucherType.name",
      key: "voucherType.name",
      render: (_: any, record: IAcVoucher) => record.voucher_type.name,
  },

    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (_: any, record: IAcVoucher) => (
          <FormatCurrencyWithSymbol amount={record.amount} />
      ),
      sorter: (a, b) => a.amount - b.amount,
  },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <a type="link" onClick={() => handleEdit(record)}>
            <EditTwoTone />
          </a>
          <Popconfirm title="Are you sure to delete this record?" onConfirm={() => handleDelete(record.id)} okText="Yes" cancelText="No">
            <a>
              <DeleteTwoTone />
            </a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      {/* Title and Create Button */}
      <Title level={4}>Voucher Register</Title>
      <Button type="primary" onClick={handleAdd} style={{ marginBottom: 16 }}>
        Create
      </Button>

      {/* Voucher Table */}
      <Table size="small" dataSource={acVouchers} columns={columns} loading={loading} rowKey="id" />

      {/* Modal for Creating/Editing Voucher */}
      <Modal
        title={isEditMode ? "Change Voucher" : "Create Voucher"}
        open={isModalVisible}
        onOk={handleSave}
        onCancel={handleCancel}
        width={800}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleSave}>
            {isEditMode ? "Change" : "Create"}
          </Button>,
        ]}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            voucherDate: dayjs(),
            voucherTypeId: defaultVoucherTypeId,
          }}
        >
          {/* Voucher Header Fields */}
          <Card bordered={false} style={{ background: "#f5f5f5" }}>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  name="voucherTypeId"
                  label="Voucher Type"
                  rules={[{ required: true, message: "Please select a voucher type" }]}
                >
                  <Select placeholder="Select Voucher Type" disabled={isEditMode}>
                    {voucherTypes.map((type) => (
                      <Select.Option key={type.id} value={type.id}>
                        {type.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="code" label="Voucher Code">
                  <Input readOnly placeholder="Generated code" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="voucherDate"
                  label="Date"
                  rules={[{ required: true, message: "Please select a date" }]}
                >
                  <DatePicker format={DATE_FORMAT} />
                </Form.Item>
              </Col>
            </Row>
          </Card>

          {/* Voucher Details Section */}
          <Divider orientation="left">
            <Title level={5}>Voucher Details</Title>
          </Divider>

          <Form.List
            name="details"
            initialValue={[
              { dr: null, cr: null, ac_chart_id: null },
              { dr: null, cr: null, ac_chart_id: null },
            ]}
          >
            {(fields: FormListFieldData[], { add, remove }: FormListOperation) => (
              <>
                {/* Map through each row in the form list */}
                {fields.map((field, index) => (
                  <Row key={field.key} gutter={16} align="middle">
                    <Col span={6}>
                      <Form.Item
                        {...field}
                        label="Account"
                        name={[field.name, "ac_chart_id"]}
                        rules={[{ required: true, message: "Please select an account" }]}
                      >
                        <Select placeholder="Select Account">
                          {acCharts.map((chart) => (
                            <Select.Option key={chart.id} value={chart.id}>
                              {chart.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item {...field} label="Narration" name={[field.name, "narration"]}>
                        <Input placeholder="Narration" />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        {...field}
                        label="Dr"
                        name={[field.name, "dr"]}
                        dependencies={[["details", index, "cr"]]}
                        rules={[
                          {
                            validator: async (_, drValue) => {
                              const crValue = form.getFieldValue(["details", index, "cr"]);
                              if ((drValue && crValue) || (!drValue && !crValue)) {
                                return Promise.reject(new Error("Enter Dr or Cr."));
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <InputNumber placeholder="Dr" style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        {...field}
                        label="Cr"
                        name={[field.name, "cr"]}
                        dependencies={[["details", index, "dr"]]}
                        rules={[
                          {
                            validator: async (_, crValue) => {
                              const drValue = form.getFieldValue(["details", index, "dr"]);
                              if ((crValue && drValue) || (!crValue && !drValue)) {
                                return Promise.reject(new Error("Enter Dr or Cr."));
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <InputNumber placeholder="Cr" style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      {/* Remove button for rows */}
                      {fields.length > 2 && (
                        <DeleteTwoTone style={{ fontSize: "16px", cursor: "pointer" }} onClick={() => remove(index)} />
                      )}
                    </Col>
                  </Row>
                ))}
                {/* Button to add new voucher detail rows */}
                <Button
                  type="dashed"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                  style={{ width: "100%", marginTop: "16px" }}
                >
                  Add Detail
                </Button>
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
    </>
  );
}

export default AcVouchers;
