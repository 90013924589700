import { Col, Row } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import Title from "antd/es/typography/Title";
import axios from "axios";
import { useEffect, useState } from "react";
import IAccountBalanceSummary from "../../../interfaces/IAccountBalanceSummary";
import { API_URL } from "../../../settings";

function AccountBalanceSummary() {
    const [accountSummary, setAccountSummary] = useState<
        IAccountBalanceSummary[]
    >([]);

    var [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getAcBalanceSummary()
    
      return () => {
        
      }
    }, [])
    

    const getAcBalanceSummary = () => {
        setIsLoading(true);
        axios
          .get(`${API_URL}/ac/reports/balance-summary`)
          .then((response) => {
            response.data.map((x: { [x: string]: any; id: any }) => {
              x["key"] = x.id;
            });
            console.log(response.data)
            setAccountSummary(response.data);
            setIsLoading(false);
          })
          .catch((err) => {
            // Handle error
            console.log("server error");
            setIsLoading(false);
          });
      };

    // Table columns for the account balances summary
    const accountSummaryColumns: ColumnsType<IAccountBalanceSummary> = [
        {
            title: "Account Type",
            dataIndex: "accountType",
            key: "accountType",
        },
        {
            title: "Total Debit",
            dataIndex: "totalDebit",
            key: "totalDebit",
            render: (value: number) => value.toFixed(2), // Formatting the number
            sorter: (a: IAccountBalanceSummary, b: IAccountBalanceSummary) =>
                a.totalDebit - b.totalDebit,
        },
        {
            title: "Total Credit",
            dataIndex: "totalCredit",
            key: "totalCredit",
            render: (value: number) => value.toFixed(2), // Formatting the number
            sorter: (a: IAccountBalanceSummary, b: IAccountBalanceSummary) =>
                a.totalCredit - b.totalCredit,
        },
        {
            title: "Net Balance",
            dataIndex: "netBalance",
            key: "netBalance",
            render: (value: number) => value.toFixed(2), // Formatting the number
            sorter: (a: IAccountBalanceSummary, b: IAccountBalanceSummary) =>
                a.netBalance - b.netBalance,
        },
    ];

    return (
        <>
            <Row>
                <Col md={24}>
                    <div>
                        <Title level={3}>Account Balances Summary</Title>
                        <Table
                            loading={isLoading}
                            size="small"
                            dataSource={accountSummary}
                            columns={accountSummaryColumns}
                            pagination={{
                                showTotal: (total) => `Total ${total} records`,
                            }}
                            scroll={{ x: "max-content" }} // Enables horizontal scrolling on smaller screens
                            rowKey={(record) => record.accountType}
                        />
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default AccountBalanceSummary;
