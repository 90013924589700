import {
  ApartmentOutlined,
  DollarOutlined,
  PieChartOutlined,
  ProjectOutlined,
  RadarChartOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Layout, Menu } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import companyLogo from "../../assets/images/group_logo.png";
const { Header, Content, Sider } = Layout;
type MenuItem = Required<MenuProps>["items"][number];
function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const items: MenuProps["items"] = [
  getItem("Dashboard", "/", <PieChartOutlined />),
  getItem("Configuration", "relconfigsubm", <RadarChartOutlined />, [
    getItem("Module Configuration", "/real-estate-config"),
    getItem("Facing", "/facing"),
    getItem("Unit of Measurement", "/uom"),
    getItem("Plot Position", "/plot-position"),
    getItem("Project Type", "/project-type"),
    getItem("Project Category", "/project-category"),
    getItem("Project Status", "/project-status"),
  ]),
  getItem("Project Information", "relProjectSubMenu", <ProjectOutlined />, [
    getItem("Project Setup", "/project"),
    getItem("Project List", "/projects"),
  ]),
  getItem("Item Information", "relItemSubMenu", <ApartmentOutlined />, [
    getItem("Item Setup", "/item"),
    getItem("Item List", "/items"),
  ]),
  getItem("Page", "pagesSubMenu", <SettingOutlined />, [
    getItem("About Us", "aboutUsSubMenu", null, [
      getItem("Corporate Profile", "/corporate-profile"),
      getItem("Mission Vision, Values", "/mission-and-vision"),
    ]),
    getItem("Messages", "messagesSubMenu", null, [
      getItem("Chairman Message", "/message-chairman"),
      getItem("Managing Director Message", "/message-managing-director"),
      getItem("Director Message", "/message-director"),
    ]),
    getItem("CSR", "CSRSubMenu", null, [
      getItem("CSR Info", "/csr"),
      getItem("CSR List", "/csrs"),
    ]),
    getItem("Gallery", "GallerySubMenu", null, [
      getItem("Gallery Info", "/gallery"),
      getItem("Gallery List", "/galleries"),
    ]),
  ]),
  getItem("Finance", "financeSubMenu", <DollarOutlined />, [
    getItem("Configuration", "ConfigurationSubMenu", null, [
      getItem("Voucher Types", "/ac/voucher-types"),
      getItem("AC Natures", "/ac/natures"),
      getItem("Chart of Account", "/ac/charts"),
    ]),
    getItem("Voucher", "VoucherSubMenu", null, [
      getItem("Voucher Register", "/ac/vouchers"),
    ]),
    getItem("Accounts Reports", "acReportsSubMenu", null, [
      getItem("Accounts Report", "/ac/reports/accounts-summary"),
      getItem("Balances Summary", "/ac/reports/balance-summary"),
    ]),
  ]),
  getItem("Settings", "Settingssubm", <RadarChartOutlined />, [
    getItem("Region", "/region"),
    getItem("Sub Region", "/sub-region"),
    getItem("Company", "CompanySubMenu", null, [
      getItem("Company Info", "/company"),
      getItem("Company List", "/companies"),
    ]),
  ]),
];
interface LeftSidebarComponentProps {
  collapsed: boolean;
  onToggleCollapse: (value: boolean) => void;
}
const LeftSidebarComponent: React.FC<LeftSidebarComponentProps> = ({
  collapsed,
  onToggleCollapse,
}) => {
  const navigate = useNavigate();

  const onClick: MenuProps["onClick"] = (e) => {
    navigate(e.key);
  };

  return (
    <>
      <Sider
        width={260}
        theme="light"
        breakpoint="lg"
        onBreakpoint={(broken: any) => {
          // console.log("broken", broken);
        }}
        onCollapse={(collapsed: any, type: any) => {
          onToggleCollapse(collapsed);
        }}
        trigger={null}
        collapsible
        collapsed={collapsed}
      >
        <div className="demo-logo-vertical">
          <img src={companyLogo} alt="" />
        </div>
        <Menu
          onClick={onClick}
          defaultSelectedKeys={["/"]}
          defaultOpenKeys={["invsum"]}
          mode="inline"
          items={items}
          style={{ height: "100%", borderRight: 0 }}
        />
      </Sider>
    </>
  );
};

export default LeftSidebarComponent;
