import { DeleteTwoTone, EditTwoTone, EyeTwoTone } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Space,
  Spin,
  message,
} from "antd";
import Table, { ColumnsType } from "antd/es/table";
import Title from "antd/es/typography/Title";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import IFacing from "../../../../interfaces/IFacing";
import { API_URL } from "../../../../settings";

function Facing() {
  const [tableLoadingSpin, setTableSpinLoading] = useState(false);
  const [facings, setFacings] = useState<IFacing[]>([]);
  const [facingForm] = Form.useForm();

  const [facingId, setFacingId] = useState<number>();
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [modalState, setModalState] = useState("CREATE");
  const [modalOkButtonText, setModalOkButtonText] = useState("Create");

  // Modal related properties
  const [modalLoadingSpin, setModalSpinLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalConfirmLoading, setModalConfirmLoading] = useState(false);

  useEffect(() => {
    getFacingList();
  }, []);

  useEffect(() => {
    setModalOkButtonText(modalState === "CREATE" ? "Create" : "Change");
    setIsFormDisabled(modalState === "VIEW");
    if (modalState === "CREATE") setFacingId(undefined);
  }, [modalState]);

  const getFacingList = async () => {
    setTableSpinLoading(true);
    try {
      const { data } = await axios.get(`${API_URL}/facings`);
      setFacings(data.map((x: IFacing) => ({ ...x, key: x.id })));
    } catch {
      console.log("Server error");
    } finally {
      setTableSpinLoading(false);
    }
  };

  const showModal = () => {
    clearModalFields();
    setModalOpen(true);
  };

  const clearModalFields = () => {
    facingForm.resetFields();
    facingForm.setFieldsValue({ activeStatus: true });
  };

  const handleCancel = () => {
    setModalOpen(false);
    setModalSpinLoading(false);
    setModalState("CREATE");
  };

  const fetchFacingById = async (id: number) => {
    setModalSpinLoading(true);
    try {
      const { data } = await axios.get(`${API_URL}/facings/${id}`);
      facingForm.setFieldsValue(data);
    } catch {
      console.log("Server error");
    } finally {
      setModalSpinLoading(false);
    }
  };

  const handleSubmit = async () => {
    try {
      const values = await facingForm.validateFields();
      setModalConfirmLoading(true);
      const url = `${API_URL}/facings${modalState === "UPDATE" ? `/${facingId}` : ""}`;
      const method = modalState === "CREATE" ? "post" : "put";

      await axios[method](url, values);
      setModalOpen(false);
      getFacingList();
      clearModalFields();
      setModalState("CREATE");
    } catch (err) {
      console.log("Error:", err);
    } finally {
      setModalConfirmLoading(false);
    }
  };

  const handleDelete = async (id: number) => {
    try {
      await axios.delete(`${API_URL}/facings/${id}`);
      getFacingList();
      message.success("Deleted Successfully.");
    } catch {
      console.log("Server error");
    }
  };

  const handleView = (id: number) => {
    setFacingId(id);
    setModalState("VIEW");
    fetchFacingById(id);
    setModalOpen(true);
  };

  const handleEdit = (id: number) => {
    setFacingId(id);
    setModalState("UPDATE");
    fetchFacingById(id);
    setModalOpen(true);
  };

  const facingColumns: ColumnsType<IFacing> = [
    {
      title: "Facing Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (_: any, record: IFacing) => moment.utc(record.created_at).local().format("DD-MMM-YYYY"),
    },
    {
      title: "Modified Date",
      dataIndex: "lastModifiedDate",
      key: "lastModifiedDate",
      render: (_: any, record: IFacing) => moment.utc(record.updated_at).local().format("DD-MMM-YYYY"),
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: IFacing) => (
        <Space size="middle">
          <a onClick={() => handleView(record.id)}><EyeTwoTone /></a>
          <a onClick={() => handleEdit(record.id)}><EditTwoTone /></a>
          <Popconfirm
            title="Are you sure to delete this record?"
            onConfirm={() => handleDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <a><DeleteTwoTone /></a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Row>
        <Col md={24}>
          <Title level={4}>Facing</Title>
          <Button type="primary" onClick={showModal}>Create</Button>
          <Table
            loading={tableLoadingSpin}
            size="small"
            dataSource={facings}
            columns={facingColumns}
            scroll={{ x: "max-content" }}
          />

          <Modal
            title="Facing"
            open={modalOpen}
            onOk={handleSubmit}
            confirmLoading={modalConfirmLoading}
            onCancel={handleCancel}
            okText={modalOkButtonText}
            okButtonProps={{ disabled: isFormDisabled }}
          >
            <Spin spinning={modalLoadingSpin}>
              <Form
                name="facingForm"
                form={facingForm}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={{ remember: true }}
                autoComplete="off"
                disabled={isFormDisabled}
              >
                <Form.Item
                  label="Facing Name"
                  name="name"
                  rules={[{ required: true, message: "Facing Name can not be null!" }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item name="description" label="Description">
                  <Input.TextArea />
                </Form.Item>
              </Form>
            </Spin>
          </Modal>
        </Col>
      </Row>
    </>
  );
}

export default Facing;
