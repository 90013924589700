import { Col, Row, Spin, Statistic, StatisticProps, theme } from "antd";
import React, { useEffect, useState } from "react";
import BarChart from "./BarChart";
import LineChart from "./LineChart";
import PieChart from "./PieChart";
import axios from "axios";
import { API_URL } from "../../../settings";
import ItemData from "../../../interfaces/IItemData";
import IAccountSummaryResponse from "../../../interfaces/AccountSummaryResponse";
import CountUp from "react-countup";

const formatter: StatisticProps["formatter"] = (value) => (
  <CountUp end={value as number} separator="," />
);

const Dashboard: React.FC = () => {
  const [acSummaryResponse, setAcSummaryResponse] =
    useState<IAccountSummaryResponse>();
  const [itemData, setItemData] = useState<ItemData[]>([]);
  const [itemApiLoading, setItemApiLoading] = useState<boolean>(false);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  useEffect(() => {
    getItemDataList();
    getAcSummary();
    return () => {};
  }, []);

  const getAcSummary = () => {
    axios
      .get(`${API_URL}/ac/reports/summary`)
      .then((response) => {
        setAcSummaryResponse(response.data);
        console.log(response.data);
      })
      .catch((err) => {
        // Handle error
        console.log("server error", err);
      });
  };

  const getItemDataList = () => {
    setItemApiLoading(true);
    axios
      .get(`${API_URL}/items/sum-by-project`)
      .then((response) => {
        response.data.map((x: { [x: string]: any; id: any }) => {
          x["key"] = x.id;
        });
        setItemData(response.data);
        setItemApiLoading(false);
      })
      .catch((err) => {
        // Handle error
        console.log("server error", err);
        setItemApiLoading(false);
      });
  };

  return (
    <div>
      <Row gutter={48}>
        <Col span={24}>
          <Row gutter={48}>
            <Col md={8} sm={24}>
              <Statistic
                title="Total Assets (BDT)"
                value={acSummaryResponse?.totalCollection}
                precision={2}
                valueStyle={{ color: "#3f8600" }}
                formatter={formatter}
                suffix="৳"
              />
            </Col>
            <Col md={8} sm={24}>
              <Statistic
                title="Total Expenses (BDT)"
                value={acSummaryResponse?.totalExpense}
                precision={2}
                valueStyle={{ color: "#3f8600" }}
                formatter={formatter}
                suffix="৳"
              />
            </Col>
            <Col md={8} sm={24}>
              <Statistic
                title="Account Balance (BDT)"
                value={acSummaryResponse?.currentBalance}
                precision={2}
                valueStyle={{ color: "#3f8600" }}
                formatter={formatter}
                suffix="৳"
              />
            </Col>
            
          </Row>
          <hr />
          <br />

        </Col>

        <Col md={12} sm={24}>
          <div>
            <h2>Project wise Items count</h2>
            <Spin spinning={itemApiLoading}>
              <BarChart data={itemData} />
            </Spin>
          </div>
        </Col>
        <Col md={12} sm={24}>
          <div>
            <h2>Line Chart</h2>
            <Spin spinning={itemApiLoading}>
              <LineChart />
            </Spin>
          </div>
        </Col>
        <Col md={12} sm={24}>
          <div className="mt-5">
            <h2>Project wise Items Amount</h2>
            <Spin spinning={itemApiLoading}>
              <PieChart data={itemData} />
            </Spin>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
