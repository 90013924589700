import { Col, Row } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import Title from "antd/es/typography/Title";
import { useEffect, useState } from "react";
import IAcVoucherType from "../../../interfaces/IAcVoucherType";
import { CheckCircleOutlined, CheckCircleTwoTone } from "@ant-design/icons";
import axios from "axios";
import { API_URL } from "../../../settings";

function AcVoucherType() {
    var [tableLoadingSpin, setTableSpinLoading] = useState(false);
    const [acVoucherTypes, setAcVoucherTypes] = useState<IAcVoucherType[]>([]);


    

    // table rendering settings
    const acVoucherTypeColumns: ColumnsType<IAcVoucherType> = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: "Alias",
            dataIndex: "alias",
            key: "alias",
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
        },
        {
            title: "Default?",
            dataIndex: "default",
            key: "default",
            render: (_: any, record: IAcVoucherType) => {
                if (record.is_default ==1 ) {
                    return (
                        <span>
                            <CheckCircleTwoTone twoToneColor="#52c41a" /> Yes
                        </span>
                    );
                } else {
                    return (
                        <span>
                            <CheckCircleOutlined /> No
                        </span>
                    );
                }
            },
        },
    ];

    const getAcNatures = () => {
        setTableSpinLoading(true);
        axios
          .get(`${API_URL}/ac/charts`)
          .then((response) => {
            response.data.map((x: { [x: string]: any; id: any }) => {
              x["key"] = x.id;
            });
            setAcVoucherTypes(response.data);
            setTableSpinLoading(false);
          })
          .catch((err) => {
            // Handle error
            console.log("server error");
            setTableSpinLoading(false);
          });
      };

      useEffect(() => {
        getAcNatures();
    
        return () => {};
      }, []);

    return (
        <>
            <Row>
                <Col md={24}>
                    <div>
                        <Title level={3}>Voucher Type</Title>
                        <Table
                            loading={tableLoadingSpin}
                            size="small"
                            pagination={{
                                showTotal: (total) => `Total ${total} records`,
                            }}
                            dataSource={acVoucherTypes}
                            columns={acVoucherTypeColumns}
                            scroll={{ x: "max-content" }} // Enables horizontal scrolling on smaller screens
                        />
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default AcVoucherType;
